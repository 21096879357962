<template>
  <div>
    <b-icon
      icon="exclamation-circle"
      class="article"
      style="width: 1.5rem; height: 1.5rem; cursor: pointer"
      @click="clickModal"
    ></b-icon>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Article Information"
      id="invoice_detail"
      ref="modal"
      size="xl"
    >
      <!-- <b-media right-align vertical-align="center"> -->
      <b-overlay :show="loading">
        <b-row v-if="exhibit != null">
          <b-col md="6" class="my-1">
            <div class="my-1">
              <b-form-group
                label-for="status"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="TE SKU ID"
              >
                <b-form-input
                  v-model="exhibit.sku"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="sku"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="my-1">
              <b-form-group
                label-for="category"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="Category"
              >
                <b-form-input
                  v-model="exhibit.category"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="category"
                  disabled
                ></b-form-input
              ></b-form-group>
            </div>
            <div class="my-1">
              <b-form-group
                label-for="brand"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="Brand"
                ><b-form-input
                  v-model="exhibit.brand"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="brand"
                  disabled
                ></b-form-input
              ></b-form-group>
            </div>
            <div class="my-1">
              <b-form-group
                label-for="brand_article_number"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="Brand Article Number"
                ><b-form-input
                  v-model="exhibit.brand_article_number"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="brand_article_number"
                  disabled
                ></b-form-input
              ></b-form-group>
            </div>
            <div class="my-1">
              <b-form-group
                label-for="article_name"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="Article Name"
                ><b-form-input
                  v-model="exhibit.product_name"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="article_name"
                  disabled
                ></b-form-input
              ></b-form-group>
            </div>
            <div class="my-1">
              <b-form-group
                label-for="ean_number"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="EAN Number"
                ><b-form-input
                  v-model="exhibit.ean_number"
                  :type="'text'"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="ean_number"
                  disabled
                ></b-form-input
              ></b-form-group>
            </div>
          </b-col>
          <b-col md="6" class="my-1">
            <div class="my-1">
              <b-form-group
                label-for="image"
                label-cols-sm="4"
                label-cols-lg="2"
                label-align-sm="right"
                label-size="sm"
                label="Image"
              >
                <img
                  v-if="exhibit.image_url != null && exhibit.image_url != ''"
                  class="image"
                  :src="exhibit.image_url"
                  alt="image"
                />
                <img
                  v-else
                  class="image pointer"
                  alt="edit"
                  src="@/assets/media/users/default.png"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <!-- </b-media> -->
        <b-row>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="length"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Length"
            >
              <b-form-input
                v-model="exhibit.length"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="length"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="width"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Width"
              ><b-form-input
                v-model="exhibit.width"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="width"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="depth"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Depth"
              ><b-form-input
                v-model="exhibit.depth"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="depth"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="volume"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Volume"
              ><b-form-input
                v-model="exhibit.volume"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="volume"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="color"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Color"
              ><b-form-input
                v-model="exhibit.color"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="color"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label-for="size"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Size"
              ><b-form-input
                v-model="exhibit.size"
                :type="'text'"
                :placeholder="this.$t('page.please_choose_input')"
                id="size"
                disabled
              ></b-form-input
            ></b-form-group>
          </b-col>
          <ReferenceLink
            v-if="exhibit.reference_link != null"
            :link="exhibit.reference_link"
            :text="'Reference Link'"
          ></ReferenceLink>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">
          {{ $t('page.close') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import ReferenceLink from '@/components/ReferenceLink/Index.vue';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'ArticleInformation',
  components: { ReferenceLink },
  data() {
    return {
      showModal: false,
      loading: false,
      exhibit: {
        id: null,
        sku: null,
        category: null,
        image_url: null,
        brand: null,
        brand_article_number: null,
        product_name: null,
        color: null,
        size: null,
        ean_number: null,
        retail_price: null,
        length: null,
        width: null,
        depth: null,
        volume: null,
        reference_link: null
      }
    };
  },
  props: ['purchaseTargetId', 'productId'],

  methods: {
    clickModal() {
      this.showModal = true;
      this.loading = true;
      this.productView();
    },
    productView() {
      apiBus.purchase
        .productView({
          product_id: this.productId,
          purchase_target_id: this.purchaseTargetId
        })
        .then(data => {
          console.log(data);
          this.productViewSuccess(data.data.data);
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: '网络波动' // 提示内容
          });
          var data = {
            id: '1',
            sku: '10000000001',
            category: null,
            image_url:
              'https://ocps-cdn.travel-easy.cn/media/catalog/product/cache/30ab8017e981a72edd8b28618159a0fc/5/2/52900001_1.png',
            brand: 'RIMOWA',
            brand_article_number: '500877 DRW00 9522',
            product_name: 'Essential 53 BLUE',
            color: 'green',
            size: 1,
            ean_number: 5,
            retail_price: 100,
            length: 1,
            width: 1,
            depth: 1,
            volume: 1,
            reference_link:
              'https://www.celine.com/de-de/celine-fur-damen/handtaschen/triomphe/triomphe-schultertasche-in-triomphe-canvas-und-kalbsleder-194142CUZ.04LU.html'
          };
          this.productViewSuccess(data);
        });
    },
    productViewSuccess(data) {
      this.loading = false;
      this.exhibit = data;
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image {
  max-width: 100%;
  max-height: 300px;
}
/* .article {
  color: #e1e1ef;
} */
</style>
